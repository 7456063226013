import React from 'react';
import { Trans } from 'react-i18next';
import styled, { css } from 'styled-components';
import {
  WAYTYPEID_GHEED_COINS,
  WAYTYPE_TWITTER_FOLLOW_TITLE,
  WAYTYPE_TWITTER_RETWEET_TITLE,
  WAYTYPE_TWITTER_VIEW_TWEET_TITLE,
  WAYTYPE_TWITCH_FOLLOW_TITLE
} from 'helpers/const';

const Profile = styled.span`
    text-overflow: ellipsis;
    flex: 1 1 auto;
    border-bottom: solid 1px transparent;

    
`

const Available = styled.span`
  ${({ complete }) => !complete && css`color: #fed532;`}
`


const BoxTitle = (open, complete, way, display_way, waytype, params) => {
    const isMaskedTitle = [WAYTYPE_TWITTER_FOLLOW_TITLE, WAYTYPE_TWITTER_RETWEET_TITLE, WAYTYPE_TWITTER_VIEW_TWEET_TITLE, WAYTYPE_TWITCH_FOLLOW_TITLE].includes(way.title);



    const normalTitle = <Trans
    i18nKey={`waytypes.${display_way.WAYTYPEID}.link`}
    values={{
        displayName: display_way.WAYTYPEID === WAYTYPEID_GHEED_COINS ?  display_way.beneficiary_display_name || waytype.entrylabel : waytype.entrylabel || display_way.beneficiary_display_name,
        beneficiary: display_way.BENEFICIARY,
      ...params
    }}
  >
    Follow
            <Profile>
      x
            </Profile>
    on y<Available complete></Available>
  </ Trans>

  return  isMaskedTitle == false ? display_way.title || normalTitle : normalTitle;
}

export default BoxTitle;