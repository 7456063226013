import React from 'react';
import Box from './_Box';
import Button from 'common/MaterialUI/Button';
import Explanation from './_Explanation';
import Description from './_Description';
import { utils } from 'helpers/utils';
import styled from 'styled-components';
import { media } from 'styles';
import { runtimeConfig } from '../../../../../../runtimeConfig';
import {
    WAYTYPE_TWITCH_FOLLOW_TITLE,
    WAYTYPES
} from 'helpers/const'
const FrameLayout = styled.iframe`
  width: 100%;
  height: 100%; 
  border: none;
  overflow: auto;
  margin: 0 auto;
  position: absolute;
  
  max-width: 100%;
  ${media.tablet`
  max-width: 500px;
  `}
`
const FrameContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 463px;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  ${media.tabletMax`
    display: none;
  `}
`
const embedContent = way => {
    if(way.title == WAYTYPE_TWITCH_FOLLOW_TITLE) {
        let twitchAccount = way.beneficiary_display_name.replace('https://www.twitch.tv/', '');
        return <React.Fragment> <iframe src={`https://player.twitch.tv/?channel=${twitchAccount}&parent=${runtimeConfig.HOST}`} frameborder="0" allowfullscreen="true" scrolling="no" height="378" width="620"></iframe></React.Fragment>;
    }
}
const TwitchMasked = ({ way, display_way, waytype, toggle, handleOpen, handleContinue, handleClose, ...other }) => {

    return <Box {...{ way, display_way, waytype, toggle, ...other }}
                color={'#6441A4'}
                icon={'icon-Ico20-Social-Twitch'}
                controls={(complete, loading, t) => <React.Fragment>
                    <Button outline small onClick={toggle(display_way)}>{t("cancel")}</Button>
                    <Button secondary small disabled={complete || loading} onClick={handleContinue(display_way, null, toggle(display_way))}>{t("continue")}</Button>
                </React.Fragment>}
    ><Description way={display_way} />
        {/*embedContent(way)*/}
        <Explanation way={display_way} waytype={WAYTYPES[display_way.WAYTYPEID]} /></Box >
}
export default TwitchMasked