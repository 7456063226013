import React from 'react';
import { compose } from 'redux';
import { Translation, Trans } from 'react-i18next';
import Link from 'redux-first-router-link';
import Button from 'common/MaterialUI/Button';
import styled from 'styled-components'
import { connect } from 'react-redux';
import language from '../../store/selectors/language';
import withAuth from '../../hoc/withAuth';
import isStreamer from '../../store/selectors/isStreamer';

const ButtonHolder = styled.div`
display: flex;
justify-content: center;

 > * + * {
   margin-left: 5px !important;
 }
`


const Streamers = ({ login, language }) => <Translation>{t => (<React.Fragment>
  <div className="ccc-introbox2-wrapper ">
    <div className="ccc-introbox2-container">
      <div className="ccc-introbox2-column-text">
        <article>
          <h2 className="ccc-h2">{t("QuickTour/Streamers.getthemost")}</h2>
          <h4 className="ccc-h4">{t("QuickTour/Streamers.morereach")}</h4>
        </article>
      </div>
      <div className="ccc-introbox2-column-image">
        <img src={`/static/assets/images/quicktour/Quicktour-03-Streamer-Section1-${language}.png`} alt=" " />
      </div>
    </div>
  </div>


  <div className="ccc-contentbox-wrapper ccc-contentbox-whitebg ccc-contentbox-imageleft">
    <div className="ccc-contentbox-container">
      <div className="ccc-contentbox-column-image">
        <video playsInline autoPlay loop muted>
          <source src={`/static/assets/images/quicktour/Quicktour-03-Streamer-Section2-${language}.mp4`} type="video/mp4" />
        </video>
      </div>
      <div className="ccc-contentbox-column-text">
        <article>
          <h6 className="ccc-h6">{t("QuickTour/Streamers.maxflex")}</h6>
          <h3 className="ccc-h3">{t("QuickTour/Streamers.individually")}</h3>
          <p className="ccc-p">{t("QuickTour/Streamers.enjoytotalfreedom")}</p>
          <hr />
        </article>
      </div>
    </div>
  </div>

  <div className="ccc-contentbox-wrapper ccc-contentbox-imageright">
    <div className="ccc-contentbox-container">
      <div className="ccc-contentbox-column-text">
        <article>
          <h6 className="ccc-h6">{t("QuickTour/Streamers.communitysharing")}</h6>
          <h3 className="ccc-h3">{t("QuickTour/Streamers.moresuccessful")}</h3>
          <p className="ccc-p">{t("QuickTour/Streamers.withgheed")}</p>
          <hr />
        </article>
      </div>
      <div className="ccc-contentbox-column-image">
        <img src="/static/assets/images/quicktour/Quicktour-03-Streamer-Section3.gif" alt=" " />
      </div>
    </div>
  </div>

  <div className="ccc-contentbox-wrapper ccc-contentbox-whitebg ccc-contentbox-imageleft">
    <div className="ccc-contentbox-container">
      <div className="ccc-contentbox-column-image">
        <video playsInline autoPlay loop muted>
          <source src="/static/assets/images/quicktour/Quicktour-03-Streamer-Section4.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="ccc-contentbox-column-text">
        <article>
          <h6 className="ccc-h6">{t("QuickTour/Streamers.communityfocus")}</h6>
          <h3 className="ccc-h3"><Trans i18nKey="QuickTour/Streamers.rewardyourloyal">Reward your loyal community with <span className="ccc-orange">Loyalty Coins</span></Trans></h3>
          <p className="ccc-p">{t("QuickTour/Streamers.viewerswhoactively")}</p>
          <hr />
        </article>
      </div>
    </div>
  </div>


  <div className="ccc-2col-wrapper">
    <div className="ccc-2col-container">
      <h3 className="ccc-h3">{t("QuickTour/Streamers.usegheed")}</h3>
      <div className="ccc-2col-row">
        <div className="ccc-contentbox ccc-2col-box">
          <img src="/static/assets/images/quicktour/Quicktour-03-Streamer-Section5-01.png" alt=" " />
          <h4 className="ccc-h4">{t("QuickTour/Streamers.shopprizes")}</h4>
          <p className="ccc-p">{t("QuickTour/Streamers.prizesfromourshop")}</p>
        </div>
        <div className="ccc-contentbox ccc-2col-box ccc-2col-rightbox">
          <img src="/static/assets/images/quicktour/Quicktour-03-Streamer-Section5-02.png" alt=" " />
          <h4 className="ccc-h4">{t("QuickTour/Streamers.ownprizes")}</h4>
          <p className="ccc-p">{t("QuickTour/Streamers.raffleyourown")}</p>
        </div>
      </div>
      <div className="ccc-2col-row">
        <div className="ccc-contentbox ccc-2col-box">
          <img src="/static/assets/images/quicktour/Quicktour-03-Streamer-Section5-03.png" alt=" " />
          <h4 className="ccc-h4">{t("QuickTour/Streamers.privategiveaways")}</h4>
          <p className="ccc-p">{t("QuickTour/Streamers.privategiveawaysdonotappear")}</p>
        </div>
        <div className="ccc-contentbox ccc-2col-box ccc-2col-rightbox">
          <img src="/static/assets/images/quicktour/Quicktour-03-Streamer-Section5-04.png" alt=" " />
          <h4 className="ccc-h4">{t("QuickTour/Streamers.maxreach")}</h4>
          <p className="ccc-p">{t("QuickTour/Streamers.publishandpromote")}</p>
        </div>
      </div>
    </div>
  </div>


  <div className="ccc-contentbox-wrapper ccc-contentbox-whitebg ccc-contentbox-imageleft">
    <div className="ccc-contentbox-container">
      <div className="ccc-contentbox-column-image">
        <video playsInline autoPlay loop muted>
          <source src="/static/assets/images/quicktour/Quicktour-03-Streamer-Section6.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="ccc-contentbox-column-text">
        <article>
          <h6 className="ccc-h6">{t("QuickTour/Streamers.regionalfilters")}</h6>
          <h3 className="ccc-h3">{t("QuickTour/Streamers.globally")}</h3>
          <p className="ccc-p">{t("QuickTour/Streamers.assignspecificregions")}</p>
          <hr />


        </article>
      </div>
    </div>
  </div>

  <div className="ccc-quote-wrapper">
    <div className="ccc-quote-container">
      <h3 className="ccc-h3">{t("QuickTour/Streamers.bigstreamers")}</h3>
      <div className="ccc-quote-row2">
        <div className="ccc-quote-box2 ccc-contentbox">
          <div className="ccc-quote-avatar"><img src="/static/assets/images/testimonials/streamer-staiy.png" alt=" " /></div>
          <h4 className="ccc-h4"><a href="https://gheed.com/streamers/162279">Staiy</a></h4>
          <ButtonHolder>
            <Button nopadding outline href="https://www.twitch.tv/staiy" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Twitch" /></Button>
            <Button nopadding outline href="https://twitter.com/StaiyLIVE" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Twitter" /></Button>
            <Button nopadding outline href="https://www.instagram.com/staiylive/" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Instagram" /></Button>
          </ButtonHolder>
        </div>
        <div className="ccc-quote-box2 ccc-contentbox">
          <div className="ccc-quote-avatar"><img src="/static/assets/images/testimonials/streamer-p4wny.png" alt=" " /></div>
          <h4 className="ccc-h4"><a href="https://gheed.com/streamers/202405">P4wnyhof</a></h4>
          <ButtonHolder>
            <Button nopadding outline href="https://www.twitch.tv/p4wnyhof" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Twitch" /></Button>
            <Button nopadding outline href="https://twitter.com/p4wnyhof" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Twitter" /></Button>
            <Button nopadding outline href="https://www.facebook.com/p4wnyhof" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Facebook" /></Button>
          </ButtonHolder>
        </div>
        <div className="ccc-quote-box2 ccc-contentbox">
          <div className="ccc-quote-avatar"><img src="/static/assets/images/testimonials/streamer-tinkerleo.png" alt=" " /></div>
          <h4 className="ccc-h4"><a href="https://gheed.com/streamers/215147">Tinkerleo</a></h4>
          <ButtonHolder>
            <Button nopadding outline href="https://www.twitch.tv/tinkerleo" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Twitch" /></Button>
            <Button nopadding outline href="https://twitter.com/Tinkerleo" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Twitter" /></Button>
            <Button nopadding outline href="https://www.instagram.com/die_tinkerleo/" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Instagram" /></Button>
          </ButtonHolder>
        </div>

      </div>
      <div className="ccc-quote-row2">
        <div className="ccc-quote-box2 ccc-contentbox">
          <div className="ccc-quote-avatar"><img src="/static/assets/images/testimonials/streamer-lory.png" alt=" " /></div>
          <h4 className="ccc-h4"><a href="https://gheed.com/streamers/159725">Lory</a></h4>
          <ButtonHolder>
            <Button nopadding outline href="https://www.twitch.tv/lory" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Twitch" /></Button>
            <Button nopadding outline href="https://twitter.com/LoryTV" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Twitter" /></Button>
            <Button nopadding outline href="https://www.instagram.com/lory.tv/" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Instagram" /></Button>
          </ButtonHolder>
        </div>
        <div className="ccc-quote-box2 ccc-contentbox">
          <div className="ccc-quote-avatar"><img src="/static/assets/images/testimonials/streamer-shlorox.png" alt=" " /></div>
          <h4 className="ccc-h4"><a href="https://gheed.com/streamers/206695">Shlorox</a></h4>
          <ButtonHolder>
            <Button nopadding outline href="https://www.twitch.tv/shlorox" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Twitch" /></Button>
            <Button nopadding outline href="https://twitter.com/shlorox/" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Twitter" /></Button>
            <Button nopadding outline href="https://www.instagram.com/shlorox/" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Instagram" /></Button>
          </ButtonHolder>
        </div>
        <div className="ccc-quote-box2 ccc-contentbox">
          <div className="ccc-quote-avatar"><img src="/static/assets/images/testimonials/streamer-bonjwa.png" alt=" " /></div>
          <h4 className="ccc-h4"><a href="https://gheed.com/streamers/222316">Bonjwa</a></h4>
          <ButtonHolder>
            <Button nopadding outline href="https://www.twitch.tv/bonjwa" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Twitch" /></Button>
            <Button nopadding outline href="https://twitter.com/bonjwade" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Twitter" /></Button>
            <Button nopadding outline href="https://www.instagram.com/bonjwade/" target="_blank" rel="noopener noreferrer"><span className="icon-Ico20-Social-Instagram" /></Button>
          </ButtonHolder>
        </div>
      </div>
    </div>
  </div>

  <div className="ccc-cta-wrapper">
    <div className="ccc-cta-container">
      <h2 className="ccc-h2">{t("QuickTour/Streamers.ready")}</h2>
      <h4 className="ccc-h4"><Trans i18nKey="QuickTour/Streamers.timetolaunch">It’s time to launch your very first GHEED giveaway. Any questions? You’ll find more detailed information about GHEED and it’s features in our <a>support area</a>.</Trans></h4>
      <ButtonHolder>
        {isStreamer ? <Button component={Link} to="/create/prizes" white onClick={login}>{t("QuickTour/Streamers.creategiveaway")}</Button> : <Button white onClick={login}>{t("QuickTour/Streamers.createaccount")}</Button>}
      </ButtonHolder>
    </div>
  </div>
</React.Fragment>)}</Translation>

const mapStateToProps = (state, ownProps) => {
  return {
    language: language(state),
    isStreamer: isStreamer(state)
  }
}


const enhance = compose(
  withAuth,
  connect(mapStateToProps),
);

export default enhance(Streamers)