import React from 'react';
import { Translation, Trans } from 'react-i18next';
import Button from 'common/MaterialUI/Button';
import { PageLayout } from 'common/PageLayout';
import Section from 'common/Section';
import { ContentBox } from '../../common/ContentBox';
import styled, { css } from 'styled-components';
import InputField from 'common/InputField';
import Grid from '@material-ui/core/Grid';
import SocialButton from 'common/SocialButton';
import MenuItem from 'common/MaterialUI/MenuItem';
import { media } from 'styles';
import i18n from '../../i18n';

export const FORM_BUSINESS = 'business';
export const FORM_INFLUENCER = 'influencer';
export const FORM_VIEWER = 'viewer';

const Content = styled.div`
  > p {
    margin-bottom: 20px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  * {
    flex: 0 1 auto;
  }
`;



const BoxLayout = styled.div`

border-radius: 3px;
border: solid 1px #e1e2ef;

position: relative;


background-size: auto 75%;
background-repeat: no-repeat;
background-position: 10% 50%;

display: flex;
justify-content: center;
align-items: center;
padding: 10px;

cursor: pointer;

:hover {
  border: solid 1px #36a6fc;
  background: #f8f9fb;
}

> img {
  width: 150px;
  height: 75px; 
  margin: 0 10px 10px;

  ${media.widescreen`
  width: 220px;
  height: 110px; 
  margin: 0;
`}

}

${media.widescreenMax`
  flex-direction: column;
`}



${media.tabletMax`
  br {
    display: none;
  }

  > img {
    display: none;
  }
`}

${media.tablet`
  min-height: 142px;
  b {
    font-size: 20px;
  }
`}


${({ active }) => active && css`
  border: solid 1px #36a6fc;
  background: #f8f9fb;

  ${media.tablet`
  :after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #88b7d5;
    border-width: 6px;
    margin-left: -6px;
  }
`}

  

`}

`

const BoxText = styled.div`
  text-align: center;
`

const BoxImg = styled.img`

`

const Box = styled(({ img, children, active, onClick }) => <BoxLayout onClick={onClick} active={active}>
  <BoxImg src={img} border="0" />
  <BoxText>{children}</BoxText>
</BoxLayout>)`
  
`

const Boxes = ({ view, onChange }) => (<Grid container spacing={3}>
  <Grid item xs={12} sm={4}><Box onClick={() => onChange(FORM_VIEWER)} img="/static/assets/images/quicktour/Quicktour-01-Intro-GHEED-for-Viewers.png" active={view == FORM_VIEWER}><Trans i18nKey="Contact/View.mode.viewer">Ich bin ein <br /><b>Zuschauer</b></Trans></Box></Grid>
  <Grid item xs={12} sm={4}><Box onClick={() => onChange(FORM_INFLUENCER)} img="/static/assets/images/quicktour/Quicktour-01-Intro-GHEED-for-Streamers.png" active={view == FORM_INFLUENCER}><Trans i18nKey="Contact/View.mode.streamer">Ich bin ein <br /><b>Streamer</b></Trans></Box></Grid>
  <Grid item xs={12} sm={4}><Box onClick={() => onChange(FORM_BUSINESS)} img="/static/assets/images/quicktour/Quicktour-01-Intro-GHEED-for-Businesses.png" active={view == FORM_BUSINESS}><Trans i18nKey="Contact/View.mode.business">Ich repräsentiere ein <br /><b>Unternehmen</b></Trans></Box></Grid>
</Grid>)


const SocialBoxLayout = styled.div`
  min-height: 86px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 2px rgba(32, 32, 34, 0.05), 0 1px 1px 0 rgba(32, 32, 34, 0.1), 0 7px 12px -10px rgba(32, 32, 34, 0.5);
  background-color: #fff;

  display:flex;
  align-items: center;
  padding: 0 20px;

  > * {
    :not(:first-child) {
      margin-left: 10px;
    }

    :last-child {
      a {
        font-weight: bold;
      }
    }
  }
`

const SocialBox = ({ icon, color, children }) => <SocialBoxLayout>{children}</SocialBoxLayout>







const SocialBoxes = () => <Grid container spacing={3}>
  <Grid item xs={12} md={6} xl={3}><SocialBox><SocialButton color="#fed532"
    className="icon-Ico16-Messages"
    href="mailto:hello@gheed.com"
  /><Trans i18nKey="Contact/View.socials.directly"><div>Write us directly at <br /><a href="mailto:hello@gheed.com">hello@gheed.com</a></div></Trans></SocialBox></Grid>
  <Grid item xs={12} md={6} xl={3}><SocialBox><SocialButton color="#36a6fc"
    className="icon-Ico20-Social-Twitter"
    href="https://twitter.com/gheed_com"
    target="_blank"
    rel="noopener noreferrer"
  /><Trans i18nKey="Contact/View.socials.twitter"><div>Follow us on <br /><a href="https://twitter.com/gheed_com"
    target="_blank"
    rel="noopener noreferrer">TwitterMasked</a></div></Trans></SocialBox></Grid>
  <Grid item xs={12} md={6} xl={3}><SocialBox><SocialButton
    color="#41a967"
    className="icon-Ico20-Social-Instagram"
    href="https://www.instagram.com/gheed_com"
    target="_blank"
    rel="noopener noreferrer"
  /><Trans i18nKey="Contact/View.socials.instagram"><div>Follow us on <br /><a href="https://www.instagram.com/gheed_com"
    target="_blank"
    rel="noopener noreferrer">Instagram</a></div></Trans></SocialBox></Grid>
  <Grid item xs={12} md={6} xl={3}><SocialBox><SocialButton
    color="#fc521e"
    className="icon-Ico20-Social-Discord"
    href="https://discord.gg/CCTuaCQ"
    target="_blank"
    rel="noopener noreferrer"
  /><Trans i18nKey="Contact/View.socials.discord"><div>Visit our <br /><a href="https://discord.gg/CCTuaCQ"
    target="_blank"
    rel="noopener noreferrer">Discord server</a></div></Trans></SocialBox></Grid>
</Grid>

const Spacer = styled.div`height:30px;`


const Placeholder = styled.span`
  color: #9697a1;
`

const renderValue = (value) => {
  return value ? value : <Placeholder>{i18n.t("Contact/View.placeholders.subject")}</Placeholder>
}

class View extends React.PureComponent {

  constructor(props) {

    const { initialView } = props

    super(props)
    this.state = {
      view: initialView
    }
  }

  handleBoxClick = (view) => {
    this.setState({ view })
  }

  render() {

    const { handleSubmit } = this.props;
    const { view } = this.state;
    const { handleBoxClick } = this;






    return (
      <Translation>
        {t => {

          const SUBJECTS = {

            [FORM_VIEWER]: [
              t("Contact/View.subject.question"),
              t("Contact/View.subject.problem"),
              t("Contact/View.subject.whereismyprize"),
              t("Contact/View.subject.feedback"),
              t("Contact/View.subject.other"),
            ],

            [FORM_INFLUENCER]: [
              t("Contact/View.subject.question"),
              t("Contact/View.subject.problem"),
              t("Contact/View.subject.promote"),
              t("Contact/View.subject.feedback"),
              t("Contact/View.subject.other"),
            ],

            [FORM_BUSINESS]: [
              t("Contact/View.subject.fullservice"),
              t("Contact/View.subject.promotion"),
              t("Contact/View.subject.shop"),
              t("Contact/View.subject.other"),
            ]
          }

          const { change, subject } = this.props;

          if (subject && SUBJECTS[view].indexOf(subject) == -1) {
            change("subject", '')
          }


          return <PageLayout>
            <Section>
              <ContentBox title={t("Contact/View.title")}>
                <form onSubmit={handleSubmit}>
                  <Content>
                    <Boxes view={view} onChange={handleBoxClick} />
                    <Spacer />
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}><InputField name="name" title={t("Contact/View.fields.name")} /></Grid>
                      {view != FORM_BUSINESS && <Grid item xs={12} lg={6}><InputField name="account" helperText="optional" title={t("Contact/View.fields.account")} /></Grid>}
                      {view == FORM_BUSINESS && <Grid item xs={12} lg={6}><InputField name="company" title={t("Contact/View.fields.company")} /></Grid>}
                      <Grid item xs={12} lg={6}><InputField name="email" title={t("Contact/View.fields.email")} /></Grid>
                      {view == FORM_BUSINESS && <Grid item xs={12} lg={6}><InputField name="phone" helperText="optional" title={t("Contact/View.fields.phone")} /></Grid>}
                      {view == FORM_BUSINESS && <Grid item xs={12} lg={6}><InputField name="website" helperText="optional" title={t("Contact/View.fields.website")} /></Grid>}
                      <Grid item xs={12} lg={6}>
                        <InputField name="subject" disableTooltip SelectProps={{ displayEmpty: true, renderValue }} title={t("Contact/View.fields.subject")} fullWidth select>

                          {SUBJECTS[view].map((subj, i) => <MenuItem key={`${view}_${i}`} value={subj} >{subj}</MenuItem>)}
                        </InputField>



                      </Grid>
                      <Grid item xs={12}>
                        <InputField
                          multiline
                          rows={4}
                          rowsMax={10}
                          name="message"
                          title={t("Contact/View.fields.message")}
                        />
                      </Grid>
                    </Grid>




                  </Content>
                  <Controls>
                    <Button primary type="submit">{t("Contact/View.send")}</Button>
                  </Controls>
                </form>
              </ContentBox>
              <SocialBoxes />
            </Section>
          </PageLayout>
        }}
      </Translation>
    );
  }
}

export default View;