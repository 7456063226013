import moment from 'moment-timezone';
import { runtimeConfig } from '../runtimeConfig';

import { WAYTYPES, PLATFORMS,
  WAYTYPEID_OTHER_VISIT,
  WAYTYPE_TWITTER_FOLLOW_TITLE,
  WAYTYPEID_TWITTER_FOLLOW,
  WAYTYPE_TWITTER_RETWEET_TITLE,
  WAYTYPEID_TWITTER_RETWEET,
  WAYTYPE_TWITTER_VIEW_TWEET_TITLE,
  WAYTYPEID_TWITTER_VIEW_TWEET,
  WAYTYPEID_TWITCH_FOLLOW,
  WAYTYPE_TWITCH_FOLLOW_TITLE } from 'helpers/const';

const randomInt = (bottom, top) => {
  return Math.floor(Math.random() * (1 + top - bottom)) + bottom;
}

const generateUUID = () => { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

const removeTags = (input_string) => {
    const regex = /(<([^>]+)>)/ig;
    const result = String(input_string).replace(regex, '');
    return result;
}

const getRealWaytypeId = (way) => {
  if (way.WAYTYPEID == WAYTYPEID_OTHER_VISIT) {
    if(way.title == WAYTYPE_TWITTER_FOLLOW_TITLE) return WAYTYPEID_TWITTER_FOLLOW;
    if(way.title == WAYTYPE_TWITTER_RETWEET_TITLE) return WAYTYPEID_TWITTER_RETWEET;
    if(way.title == WAYTYPE_TWITTER_VIEW_TWEET_TITLE) return WAYTYPEID_TWITTER_VIEW_TWEET;
    if(way.title == WAYTYPE_TWITCH_FOLLOW_TITLE) return WAYTYPEID_TWITCH_FOLLOW;
  }
  return way.WAYTYPEID;
}

export const utils = {

  socialLink: (data) => {

    let result;

    if (data.WAYTYPEID) {
      const realWayTypeId = getRealWaytypeId(data);
      const waytype = WAYTYPES[realWayTypeId]
      const replaceables = waytype.social_link.match(/\{\{([^}]*)\}\}/g)
      result = waytype.social_link

      replaceables.forEach(element => {
        const key = element.substr(2, element.length - 4)
        result = result.replace(element, data[key])
      });

    } else if (data.PLATFORMID) {

      const platform = PLATFORMS[data.PLATFORMID]
      result = platform.socialLink && platform.socialLink(data)
    }

    return result;
  },



  randomInt,
  generateUUID,
  removeTags,
  isOnDailyCooldown: (entry_date) => {
    const current = new Date();
    const currentUTCTime = current.getTime() + current.getTimezoneOffset() * 60000;
    return currentUTCTime - new Date(entry_date).getTime() < 1000 * 60 * 60 * 24
  },
  getRandomProductImageFallback: () => {
    return `/static/assets/images/creator/Product-Image-Placeholder-1.jpg`
  },
  resolveImageAsset: (asset, variant) => {

    if (asset && asset.source) {
      return asset.source
    }

    return `${runtimeConfig.PROXY_TARGET}${runtimeConfig.API_DIR}/images/${asset && asset.uuid ? asset.uuid : asset}?variant=${variant}`
  },

  important: (css) => {
    return `${css}`.split(';').join(' !important;')
  },
  extractMessageFromRESTError: (response) => {
    return response && response.data && response.data.message ? response.data.message : 'Unknown error.'
  },

  getPromiseReject: (caller, callback) => {
    let rej = null;
    new Promise((resolve, reject) => {
      caller()
        .then(result => {
          resolve(callback(result));
        })
        .catch(e => reject(e));
      rej = reject;
    });
    return rej;
  },
  chunk(arr, size) {
    return arr.reduce((ar, it, i) => {
      const ix = Math.floor(i / size);

      if (!ar[ix]) {
        ar[ix] = [];
      }

      ar[ix].push(it);

      return ar;
    }, []);
  },
  intersperse(arr, sep) {
    if (arr.length === 0) {
      return [];
    }

    return arr.slice(1).reduce(
      function (xs, x, i) {
        return xs.concat([sep, x]);
      },
      [arr[0]],
    );
  },
  move(arr, fr, to) {
    arr.splice(to, 0, arr.splice(fr, 1)[0]);
  },
  unique(arrArg) {
    return arrArg.filter((elem, pos, arr) => {
      return arr.indexOf(elem) == pos;
    });
  },
  ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  isFunction: object => {
    return Object.prototype.toString.call(object) == '[object Function]';
  },
  getObjectFromHashString: str => {
    let nstr = str.indexOf('#') === 0 ? (nstr = str.substr(1)) : str;
    return nstr.split('&').reduce((a, c) => {
      let v = c.split('=');
      if (v.length > 1) {
        a[v[0]] = v[1];
      }
      return a;
    }, {});
  },

  getObjectFromQueryString: str => {
    let nstr = str.indexOf('?') === 0 ? (nstr = str.substr(1)) : str;
    return nstr.split('&').reduce((a, c) => {
      let v = c.split('=');
      if (v.length > 1) {
        a[v[0]] = decodeURIComponent(v[1]);
      }
      return a;
    }, {});
  },
};

export default utils;

export const convertHoursAndMinutesToMoment = (time, suffix = '') => {
  return moment(
    `1970-01-01 ${time} ${suffix}`,
    suffix ? 'YYYY-MM-DD HH:mm a' : 'YYYY-MM-DD HH:mm',
  );
};