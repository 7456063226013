import styled from 'styled-components';
import classNames from 'classnames';
import { NavLink } from 'redux-first-router-link';

import React from 'react';
import { BaseGridBoxMixin, media } from 'styles';
import { Translation } from 'react-i18next';
import CookieSettings from '../CookieSettings';
import Language from './Language';
import { H5ExtraBoldBlue } from 'styles/Kit/H5ExtraBold';
import SocialButton from 'common/SocialButton';
import LinkButton from 'common/LinkButton';


const Layout = styled.div`
  background: #1d1d1d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 70;

  flex: 1 0 auto;
  min-height: 110px;
`;

const Bottom = styled.div`
  background-color: rgba(150, 151, 161, 0.15);
  min-height: 44px;
  display: flex;
  color: #828282;
  font-size: 11px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 20px;

  



`;

const Copy = styled.div``;

const Links = styled.div`
  color: #686868;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  

  ${media.tabletMax`
  justify-content: flex-start;
  margin-bottom: 5px;
  order: -1;
  max-width: 300px;
  margin-left: -5px;
`};

  ${media.tablet`
margin-right: -5px;
`} a, button {
    color: #828282;
    font-size: 11px;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    margin: 0 7px;

    :hover {
      color: #36a6fc;
    }

    :not(:last-child):after {
      position: absolute;
      display: block;
      color: #828282;
      content: '•';
      right: -10px;
    top: 0px;
    }
  }
`;

const Inner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${BaseGridBoxMixin};

  & + & {
    margin-top: 5px;
  }

  ${media.tabletMax`
  flex-direction: column-reverse;
  align-items: flex-start;
  padding: 10px 0;
    > *:first-child {
      margin-bottom: 10px;
    }
  `};
`;

const Top = styled.div`
  display: flex;
  min-width: 100vw;
  min-height: 66px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
`;

const Socials = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;

  ${media.tabletMax`
    margin: 20px 0;
  `}
`;

const LinkSection = styled.div`
  display: flex;
  

  > a {
    display: block;
    font-size: 14px;
    cursor: pointer;
    padding: 2px 0;

    ${H5ExtraBoldBlue}

    &.green {
      color: #41a967;
    }

    &.active {
      color: white;
    }

    cursor: pointer;
    :hover {
      color: #4ccbfd;
    }

    ${media.tablet`

      + a {
        margin-left: 20px;
      }
    `};
  }

  ${media.tabletMax`
    flex-direction: column;
  `};

  ${media.tablet`
  align-items: center;
  `};
`;

const TopInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${BaseGridBoxMixin};

  ${media.tabletMax`
    flex-direction: column;
    align-items: flex-start;
  `}

`;

const View = props => {
  const {
    showCookieNotice,
    cookieNotice,
  } = props;

  return (
    <Translation>
      {t => (
        <Layout className={classNames("footer", { cookies: cookieNotice })}>
          <CookieSettings />
          <Top>
            <TopInner>
              <LinkSection>
                <Language />
                <NavLink to="/quicktour/businesses">
                  {t("Footer/View.link.quicktour.businesses")}
                </NavLink>
                <NavLink to="/contact">{t('contact')}</NavLink>

              </LinkSection>
              <Socials>
                <SocialButton
                  color="#fc521e"
                  className="icon-Ico20-Social-Discord"
                  href="https://discord.gg/CCTuaCQ"
                  target="_blank"
                  rel="noopener noreferrer"
                />
                <SocialButton
                  color="#41a967"
                  className="icon-Ico20-Social-Instagram"
                  href="https://www.instagram.com/gheed_com"
                  target="_blank"
                  rel="noopener noreferrer"
                />
                <SocialButton color="#36a6fc"
                  className="icon-Ico20-Social-Twitter"
                  href="https://twitter.com/gheed_com"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </Socials>
            </TopInner>
          </Top>
          <Bottom>
            <Inner>
              <Copy>© 2019 SmartSelf GmbH</Copy>
              <Links>
                <LinkButton onClick={() => showCookieNotice()}>{t('cookies')}</LinkButton>
                <NavLink to="/terms">{t('terms')}</NavLink>
                <NavLink to="/conditions">{t('conditions')}</NavLink>
                <NavLink to="/privacy">{t('privacy')}</NavLink>
                <NavLink to="/imprint">{t('imprint')}</NavLink>
              </Links>
            </Inner>
          </Bottom>
        </Layout>
      )}
    </Translation>
  );
};

export default View;
