import React from 'react';
import { VerticalLayout } from 'common/VerticalLayout';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer';
import Modals from 'components/Modals';
import { connect } from 'react-redux';
import { NAVIGATION_CONFIG } from 'routes';
import Workers from 'components/Workers';
import { PageLayout } from 'common/PageLayout';
import { Helmet } from 'react-helmet';
import { createSelector } from 'reselect';
import NotFound from './NotFound';

import '../../node_modules/react-dates/lib/css/_datepicker.css'
import '../styles/datepicker_overrides.css'
import '../../node_modules/react-image-crop/dist/ReactCrop.css';
import '../styles/ccc.css'

import styled from 'styled-components';
import { withPageView } from '../hoc/withPageView';
import { ROUTE_TYPE_CREATE, ROUTE_TYPE_GIVEAWAY_EDIT, ROUTE_TYPE_GIVEAWAY, ROUTE_TYPE_GIVEAWAY_PREVIEW, ROUTE_TYPE_GIVEAWAY_EMBED, META_DESCRIPTION } from 'helpers/const';
import NotificationsService from './Services/Notifications';
import classNames from 'classnames';

const MainLayout = styled(VerticalLayout)`
  min-height: 100vh;
  width: 100%;

  :not(.embedded) {
    > div {
      :nth-child(2) {
        min-height: calc(100vh - 95px);
        display: flex;
      }
    }
  }

  
`;

const Loader = () => (
  <PageLayout loading={true} />
);

class App extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  handleDragOver = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { Path, Page, Submenu, RouteInfo, loading, disableFooter, embedded } = this.props;

    const metaTitle = `${RouteInfo ? RouteInfo.title : 'A giveaway a day keeps the doctor away!'} @ GHEED`;
    const metaDesc = META_DESCRIPTION;
    const metaImg = 'https://gheed.com/prod/images/10b60c5b-ed70-40e4-8758-3d7b2773f5d2?variant=original'

    const meta = [

      { property: 'description', content: metaDesc },

      { name: 'author', content: 'gheed_com' },

      { name: 'twitter:site', content: '@gheed_com' },
      { name: 'twitter:creator', content: '@gheed_com' },
      { name: 'twitter:title', content: metaTitle },
      { name: 'twitter:image', content: metaImg },

      { property: 'og:title', content: metaTitle },
      { property: 'og:site_name', content: 'gheed.com' },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:url',
        content: `https://gheed.com${Path}`,
      },
      { property: 'og:description', content: metaDesc },
      { property: 'og:image', content: metaImg },
    ];

    return (
      <MainLayout className={classNames({ embedded })} onDragOver={this.handleDragOver} onDrop={this.handleDrop}>
        <Helmet title={metaTitle} meta={meta} />
        {!embedded && <Navigation>{Submenu && <Submenu />}</Navigation>}
        {loading ? <Loader /> : <Page />}
        {(!embedded && !disableFooter) && <Footer />}
        <Modals />
        <Workers />
        <NotificationsService />
      </MainLayout>
    );
  }
}

const location = state => state.location;

const selectedState = createSelector([location], (location) => {
  const RouteInfo = NAVIGATION_CONFIG[location.type];
  const Page = RouteInfo ? withPageView(RouteInfo.page) : NotFound;
  const Path = location.pathname
  const Submenu = RouteInfo && RouteInfo.submenu

  return {
    RouteInfo: RouteInfo,
    Page,
    Submenu,
    Path,
    loading: false,
    embedded: [ROUTE_TYPE_GIVEAWAY_EMBED].indexOf(location.type) != -1 || ( [ROUTE_TYPE_GIVEAWAY, ROUTE_TYPE_GIVEAWAY_PREVIEW].indexOf(location.type) != -1 && typeof window != 'undefined' && typeof top != 'undefined' && top != window ),
    disableFooter: [ROUTE_TYPE_GIVEAWAY_EMBED].indexOf(location.type) != -1 || [ROUTE_TYPE_CREATE, ROUTE_TYPE_GIVEAWAY_EDIT].indexOf(location.type) != -1,
  };
});

const mapStateToProps = state => selectedState(state);

export default connect(mapStateToProps)(App);
