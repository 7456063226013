import { css } from 'styled-components';

export const BreakPoints = {
  widescreen: 1330,
  desktop: 1000,
  tablet: 660,
  phone: 300,
};

// iterate through the sizes and create a media template
export const media = Object.keys(BreakPoints).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const pxSize = BreakPoints[label];
  const lowerPxSize = BreakPoints[label] - 1;
  const newAcc = { ...accumulator };
  newAcc[label] = (...args) => css`
    @media (min-width: ${pxSize}px) {
      ${css(...args)};
    }
  `;
  newAcc[label + 'Max'] = (...args) => css`
    @media (max-width: ${lowerPxSize}px) {
      ${css(...args)};
    }
  `;
  return newAcc;
}, {
    retina: (...args) => css`
      @media 
        screen and (-webkit-min-device-pixel-ratio: 2), 
        screen and (min-resolution: 192dpi), 
        screen and (min-resolution: 2dppx) {
          ${css(...args)};
        }
    ` 
});

export const mediaMixin = css`
  ${p => {
    let arr = [];
    let keys = Object.keys(BreakPoints);
    for (var n = 0; n < keys.length; n++) {
      if (p['from-' + keys[n]]) {
        arr.push(media[keys[n] + 'Max']`
          display: none;
        `);
      }
      if (p['until-' + keys[n]]) {
        arr.push(media[keys[n]]`
          display: none;
        `);
      }
    }
    return arr;
  }};
`;

export const bigPaddingMixin = css`
  padding-left: 1.25em;
  padding-right: 1.25em;

  ${media.tablet`
    padding-left: 2.5em;
    padding-right: 2.5em;
  `};
`;

export const BaseGridBoxMixin = css`
  max-width: 300px;
  min-width: 300px;

  width: 100%;

  ${media.tablet`
max-width: 630px;
`};
  ${media.desktop`
max-width: 960px;
`};
  ${media.widescreen`
max-width: 1290px;
`};

  &.withsidenav {
    ${media.tablet`
    min-width: 630px;
max-width: 630px;
`};
    ${media.desktop`
max-width: 630px;
`};
    ${media.widescreen`
max-width: 960px;
`};
  }

  &.half {
    ${media.tablet`
  max-width: 630px;
  `};

    ${media.desktop`
  max-width: 960px;
  `};
    ${media.widescreen`
  max-width: 630px;
  `};

    ${media.widescreenMax`
`};
  }

  &.third {
    ${media.tablet`
  max-width: 630px;
  `};

    ${media.desktop`
  max-width: 410px;
  `};
    ${media.widescreen`
  max-width: 410px;
  `};

    ${media.widescreenMax`

`};
  }

  &.threequarters {
    ${media.tablet`
  max-width: 630px;
  `};

    ${media.desktop`
  max-width: 520px;
  `};
    ${media.widescreen`
  max-width: 850px;
  `};

    ${media.widescreenMax`

`};
  }

  &.quarter {
    ${media.tablet`
  max-width: 300px;
  `};

    ${media.desktop`
  max-width: 300px;
  `};
    ${media.widescreen`
  max-width: 300px;
  `};
  }

  &.sixthirty {
    max-width: 300px;
    width: 300px;

    ${media.tablet`
      width: 630px;
      min-width: 630px;
      max-width: 630px;
    `};
  }

  &.ninesixty {
    max-width: 300px;
    width: 300px;

    ${media.tablet`
      width: 630px;
      min-width: 630px;
      max-width: 630px;
    `};

${media.desktop`
      width: 960px;
      min-width: 960px;
      max-width: 960px;
    `};
  }

  &.bigmodal {
    max-width: 340px;
    width: 340px;

    ${media.tablet`
      width: 630px;
      min-width: 630px;
      max-width: 630px;
    `};
  }

  &.modal {
    max-width: 340px;
    width: 340px;

    ${media.tablet`
      width: 410px;
      max-width: 410px;
  `};
  }

  &.side {
    max-width: 300px;

    ${media.tablet`
        max-width: 630px;
    `};

    ${media.desktop`
      max-width: 410px;
    `};

    width: 100%;
  }
`;

export const GridBoxMixin = css`
  &
    + :not(.half):not(.third):not(.threequarters):not(.sixthirty):not(.side):not(.nomargin) {
    margin-top: 30px;
  }

  ${BaseGridBoxMixin} &.half {
    & + & {
      margin-top: 30px;
    }

    ${media.widescreen`
    & + & {
      margin-top: 0px;
    }
    `};
  }
`;

export const addBoxClass = props => {
  if (props.half) {
    return 'half';
  }

  if (props.third) {
    return 'third';
  }

  if (props.threequarters) {
    return 'threequarters';
  }

  if (props.quarter) {
    return 'quarter';
  }

  if (props.sixthirty) {
    return 'sixthirty';
  }

  if (props.ninesixty) {
    return 'ninesixty';
  }

  

  if (props.modal) {
    return 'modal';
  }

  if (props.bigmodal) {
    return 'bigmodal';
  }

  if (props.withsidenav) {
    return 'withsidenav';
  }

  if (props.side) {
    return 'side';
  }

  return null;
};

export const colors = {
  black: '#1D1D1D',
  bronze: '#916635',
  bronzedark: '#74512A',
  bronzelight: '#AA8963',
  coolblue: '#36A6FC',
  coolbluedark: '#2083FB',
  coolbluelight: '#4CCBFD',
  gold: '#E6AA42',
  golddark: '#B88835',
  goldlight: '#F0C678',
  green: '#41A967',
  greendark: '#27873E',
  greenlight: '#5BCB8F',
  grey: '#CDCDCD',
  lightgrey: '#F0F0F0',
  marineblue: '#4C66B6',
  marinebluedark: '#2E3C99',
  marinebluelight: '#648ACD',
  orange: '#FED532',
  orangedark: '#FABE19',
  orangelight: '#FFF15A',
  pinkishgrey: '#cdcdcd',
  red: '#FC521E',
  reddark: '#F52C0D',
  redlight: '#FF8432',
  silver: '#828282',
  silverdark: '#686868',
  silverlight: '#9C9C9C',
  white: '#FFF',
  warmgrey: '#828282',
};

export default colors;
