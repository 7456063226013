import { redirect } from 'redux-first-router';

import { scrollToTop } from 'helpers/scrollTo';
import { ROUTES_STREAMERONLY, ROUTE_TYPE_HOME } from 'helpers/const';
import { uiChangeAttributeAction } from '../store/modules/ui';

import isAuthed from '../store/selectors/isAuthed';
import isStreamer from '../store/selectors/isStreamer';


export const GO_HOME = dispatch => {
  const action = redirect({ type: ROUTE_TYPE_HOME }); // action tells middleware to use history.replace()
  dispatch(action); // on the server you detect a redirect as done below
};

export const onBeforeRouteChange = (dispatch, getState, { action }) => {
  const state = getState(); // up to you to handle via standard redux techniques

  // reset contest detail active tab
  const activeTab = state.location.query && state.location.query.view == 'enter' ? 2 : 1
  dispatch(uiChangeAttributeAction({ giveawayActiveTab: activeTab }));


  // CHECK Authentication
  if (action.type && action.type.indexOf('settings') != -1) {
    if (!isAuthed(state)) {
      GO_HOME(dispatch);
    }
  }

  // CHECK STREAMER ROLE
  if (
    ROUTES_STREAMERONLY.indexOf(action.type) != -1
  ) {
    if (!isStreamer(state)) {
      GO_HOME(dispatch);
    }
  }
};

export const onAfterRouteChange = (dispatch, getState, action) => {
  scrollToTop();
};
