import styled from 'styled-components';
import React from 'react';
import { media } from 'styles';
import Button from 'common/MaterialUI/Button';
import { Translation } from 'react-i18next';
import Burger from '../Burger';
import Notifications from '../Notifications';
import Logo from '../../../common/Logo';
import { Account } from './Account';
import RugMenu from './RugMenu';
import { ROUTE_TYPE_DASHBOARD_STREAMER, ROUTE_TYPE_DASHBOARD_VIEWER, ROUTE_TYPE_GIVEAWAYS, ROUTE_TYPE_QUICKTOUR, ROUTE_TYPE_BUSINESSES, ROUTE_TYPE_PRODUCTS } from '../../../helpers/const';

const Layout = styled.div`
  width: calc(100% - 74px);
  justify-content: space-between;

  height: 66px;
  background: #1d1d1d;
  display: flex;
  align-items: center;
  padding: 0;

  ${media.tablet`
  max-width: 630px;
  `};

  ${media.desktop`
  max-width: 960px;
  padding: 0;
  `}

  ${media.widescreen`
  max-width: 1290px;

`};

  .green {
    color: #41a967;
  }
`;

const LogoHolder = styled.div`
  margin-right: 20px;
  flex: 0 1 auto;
`;


const Middle = styled.div`
  display: flex;
  flex: 1 1 0;
  margin-right: 20px;
  overflow: hidden;
  ${media.tabletMax`
    display: none;
  `}
`;


const Right = styled.div`
  display: flex;
  flex: 0 1 auto;

  > * + * {
    margin-left: 10px;
  }
`;

const dashboardActive = (routeType) => () => [ROUTE_TYPE_DASHBOARD_STREAMER, ROUTE_TYPE_DASHBOARD_VIEWER].indexOf(routeType) != -1;

const RUG_PUBLIC = [
  { type: ROUTE_TYPE_GIVEAWAYS, label: 'browse_giveaways', to: '/giveaways' },
  { type: ROUTE_TYPE_QUICKTOUR, label: 'quick_tour', to: '/quicktour' },
  { type: ROUTE_TYPE_BUSINESSES, label: 'Footer/View.link.quicktour.businesses', classes: 'green', to: '/quicktour/businesses' },
]

const RUG_VIEWER = [
  { type: ROUTE_TYPE_DASHBOARD_VIEWER, label: 'dashboard', to: '/dashboard/viewer', isActive: dashboardActive },
  { type: ROUTE_TYPE_GIVEAWAYS, label: 'browse_giveaways', to: '/giveaways' },
  { type: ROUTE_TYPE_QUICKTOUR, label: 'quick_tour', to: '/quicktour' },
  { type: ROUTE_TYPE_PRODUCTS, label: 'new_giveaway', classes: 'green', to: '/settings/setup' },
]

const RUG_STREAMER = [
  { type: ROUTE_TYPE_DASHBOARD_STREAMER, label: 'dashboard', to: '/dashboard/streamer', isActive: dashboardActive },
  { type: ROUTE_TYPE_GIVEAWAYS, label: 'browse_giveaways', to: '/giveaways' },
  { type: ROUTE_TYPE_QUICKTOUR, label: 'quick_tour', to: '/quicktour' },
  { type: ROUTE_TYPE_PRODUCTS, label: 'new_giveaway', classes: 'green', to: '/create/prizes' },
]

export class MainNavigation extends React.PureComponent {
  render() {
    const {
      openBetaSignup,
      authenticated,
      login,
      logout,
      isStreamer,
      user,
      support,
      scrolling,
      routeType
    } = this.props;

    return (
      <Translation>
        {t => (
          <Layout>
            <LogoHolder>
              <Logo to="/" />
            </LogoHolder>
            <Middle>
              {!authenticated && (
                <RugMenu links={RUG_PUBLIC} routeType={routeType} />
              )}
              {authenticated && !isStreamer && (
                <RugMenu links={RUG_VIEWER} routeType={routeType} />
              )}
              {authenticated && isStreamer && (
                <RugMenu links={RUG_STREAMER} routeType={routeType} />
              )}
            </Middle>

            <Right>
              {!authenticated && (
                <Button primary onClick={login} >Login</Button>
              )}
              {authenticated && <Notifications />}
              {authenticated && (
                <Account
                  {...{ authenticated, isStreamer, user, logout, support }}
                />
              )}


              <Burger
                {...{
                  openBetaSignup,
                  authenticated,
                  isStreamer,
                  logout,
                  login,
                  user,
                  support,
                  scrolling,
                  routeType
                }}
              />

            </Right>
          </Layout>
        )}
      </Translation>
    );
  }
}

export default MainNavigation;
