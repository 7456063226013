import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Button from 'common/MaterialUI/Button';
import Link from 'redux-first-router-link';
import { Translation, Trans } from 'react-i18next';
import { MODAL_PARTNER_APPLY, modalAction } from 'rdx/modules/modals';
import { loginWithOAuthAction } from 'rdx/modules/auth';
import { PLATFORMS, PLATFORMID_TWITCH, PLATFORMID_YOUTUBE, PLATFORMID_TWITTER, PLATFORMID_DISCORD } from 'helpers/const';
import ConnectButton from './ConnectButton';
import { generateCaptcha } from 'hoc/withForm';
import EmptyBox from 'common/EmptyBox';
import { H5ExtraBoldGrey } from '../../../styles/Kit/H5ExtraBold';

import { media } from "../../../styles";


const LoginWith = styled.div`
  ${H5ExtraBoldGrey}
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;

  > span {
    white-space: nowrap;
    margin: 0 10px;
  }

  :before, :after {
    width: 100%;
    content: " ";
    border-top: solid 1px #e1e2ef;
  }
  margin: 0 0 15px;
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;

  > *:not(:first-child) {
    margin-top: 10px;
  }
`;

const Terms = styled.p`
  margin-top: 30px;
  font-size: 11px;
`;

const Margin = styled.div`
  margin: 20px;
`

const LoginBoxMargin = styled.div`
  padding: 33px 20px 0px 20px;

  ${media.tablet`
    padding: 33px 40px 0px 40px;
`} 
`

class ConnectModal extends React.PureComponent {

  state = { captcha: null }

  constructor(props) {
    super(props);
  }


  refreshCaptcha = async () => {
    const captcha = await generateCaptcha("login")
    this.setState({ captcha })

  }

  componentDidMount() {

    this.refreshCaptcha();
    this.captchaInterval = setInterval(this.refreshCaptcha, 60 * 1000)

  }

  componentWillUnmount() {
    clearInterval(this.captchaInterval)
  }



  handleCallback = platform => event => {
    event.stopPropagation();

    const { loginWithOAuth, onRequestClose, visibleCaptcha } = this.props;
    const { captcha } = this.state;

    loginWithOAuth(platform, captcha, visibleCaptcha)
    onRequestClose();


  }


  handleApply = event => {
    event.stopPropagation();
    const { applyAsStreamer } = this.props;
    applyAsStreamer();
  };


  render() {
    const { handleCallback } = this;
    const { onRequestClose, showEmailFlow } = this.props;
    const { captcha } = this.state;

    const disabled = !captcha

    return (
      <LoginBoxMargin><Translation>
        {t => (
          <React.Fragment>
            <LoginWith><span>{t("Modals/ConnectModal/Overview.loginwith")}</span></LoginWith>
            <Controls>
              {!captcha ? <Margin><EmptyBox small dark noBorder loading transparent /></Margin> : <React.Fragment>
                <ConnectButton disabled={disabled} large
                  platform={PLATFORMS[PLATFORMID_TWITCH]}
                  onClick={handleCallback(PLATFORMS[PLATFORMID_TWITCH])}
                ></ConnectButton>
                <ConnectButton disabled={disabled} large
                  platform={PLATFORMS[PLATFORMID_YOUTUBE]}
                  onClick={handleCallback(PLATFORMS[PLATFORMID_YOUTUBE])}
                ></ConnectButton>
                <ConnectButton disabled={disabled} large
                  platform={PLATFORMS[PLATFORMID_DISCORD]}
                  onClick={handleCallback(PLATFORMS[PLATFORMID_DISCORD])}
                ></ConnectButton>
              </React.Fragment>}

              <Button large primary onClick={showEmailFlow}> <span className="icon-Ico16-Messages" /><span>E-Mail</span></Button>
            </Controls>
            <Terms>
              <Trans i18nKey="modals.connect.by_connecting">
                By connecting your account you agree to accept our
                  <Link onClick={() => onRequestClose()} to="/terms">
                  terms of use
                  </Link>
                ,
                  <Link onClick={() => onRequestClose()} to="/privacy">
                  privacy policy
                  </Link>
                and
                  <Link onClick={() => onRequestClose()} to="/conditions">
                  participation conditions
                  </Link>
                .
                </Trans>
            </Terms>
          </React.Fragment>
        )}
      </Translation></LoginBoxMargin>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginWithOAuth: (platform, captcha, visibleCaptcha) => {
      dispatch(loginWithOAuthAction({ platform, captcha, visibleCaptcha }));
    },
    applyAsStreamer: () => {
      dispatch(modalAction({ modal: MODAL_PARTNER_APPLY }));
    },
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(ConnectModal);