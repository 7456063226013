import { createLogic } from 'redux-logic';
import { AUTH_LOGIN_WITH_OAUTH } from 'rdx/modules/auth';
import { OAuth } from 'rdx/modules/api/OAuth';
import { showNotificationModal } from '../modules/modals';
import { ROUTE_TYPE_GIVEAWAY, ROUTE_TYPE_GIVEAWAY_EMBED } from 'helpers/const';
import i18n from 'i18n';

const loginWithOAuthLogic = createLogic({
  type: AUTH_LOGIN_WITH_OAUTH,
  async process({ getState, action }, dispatch, done) {
    try {

      const { platform, captcha, visibleCaptcha } = action.payload

      if (!captcha) {
        return;
      }

      if (!visibleCaptcha) {
        throw new Error(i18n.t('empty_captcha_error'));
      }

      const state = getState()
      const routeType = state.location.type;
      
      await OAuth.connect(
        platform,
        null,
        captcha,
        visibleCaptcha,
        (ROUTE_TYPE_GIVEAWAY == routeType || ROUTE_TYPE_GIVEAWAY_EMBED == routeType) ? state.location.pathname : '/dashboard',
        (ROUTE_TYPE_GIVEAWAY == routeType || ROUTE_TYPE_GIVEAWAY_EMBED == routeType) ? state.location.pathname : '/',
      )

    } catch (error) {
      if (error && error.message) {
        dispatch(
          showNotificationModal({
            title: "modals.general.failed_title",
            content: error.message,
            translate: false,
          }),
        );
      } else {
        console.log(error);
      }

    }
    done();
  },
});

export default loginWithOAuthLogic;