import { openPopup } from "helpers/popup";
import { utils } from "helpers/utils";
import { runtimeConfig } from "../../../runtimeConfig";

let popupIndex = 0;

const RUNNING_AUTHS = {};

class API {
  connect(
    platform,
    scopes = null,
    captcha,
    visibleCaptcha,
    redirect_path,
    error_path,
    message,
    keepWindow = false
  ) {
    if (!platform) {
      return Promise.reject("No platform provided");
    }

    const { path } = platform;

    const identifier = `__${platform.PLATFORMID}_${scopes}`;
    RUNNING_AUTHS[identifier] =
      RUNNING_AUTHS[identifier] ||
      new Promise((resolve, reject) => {
        let url = `${runtimeConfig.API_DIR}/${path}/connect`;

        const params = [];

        popupIndex++;

        if (scopes) {
          params.push(`scope=${scopes}`);
        }
        if (captcha) {
          params.push(`captcha_response=${captcha}`);
        }

        if (visibleCaptcha) {
          params.push(`visibleCaptcha=${visibleCaptcha}`);
        }

        if (redirect_path) {
          params.push(`redirect_path=${redirect_path}`);
        }

        if (error_path) {
          params.push(`error_path=${error_path}`);
        }

        if (message) {
          params.push(`message=${message}`);
        }

        const urlWithParams = params.length
          ? `${url}?${params.join("&")}`
          : url;

        const popup = openPopup(path, urlWithParams, `${path}_${popupIndex}`);

        if (popup) {
          popup.focus();
        }

        let interval = setInterval(async () => {
          try {
            if (popup.closed) {
              clearInterval(interval);
              delete RUNNING_AUTHS[identifier];
              reject(null);
              location.reload();
            }
            if (
              (popup.location.search &&
                popup.location.search.indexOf("success") >= 0) ||
              popup.location.href.includes("dashboard")
            ) {
              clearInterval(interval);
              let locationString = popup.location.href;
              const params = utils.getObjectFromQueryString(
                popup.location.search
              );

              if (!keepWindow) {
                popup.close();
              }
              if (
                params.success == "true" ||
                locationString.includes("dashboard")
              ) {
                delete RUNNING_AUTHS[identifier];
                resolve(keepWindow ? popup : params);
              } else {
                if (keepWindow) {
                  popup.close();
                }

                delete RUNNING_AUTHS[identifier];
                reject(params);
              }
              location.reload();
            }
          } catch (error) {
            //reject(error);
          }
        }, 40);
      });
    return RUNNING_AUTHS[identifier];
  }
}

export const OAuth = new API();
export default OAuth;
