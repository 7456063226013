import { handleActions, createAction, combineActions } from 'redux-actions';
import { LOCALES } from '../../../helpers/const';
import { GET_USERS_SETTINGS_RESPONSE, PUT_USERS_SETTINGS_RESPONSE } from 'rdx/modules/api';

export const UI_CHANCE_ATTR = 'ui/CHANGE_ATTR'
export const UI_CHANGED_ROUTE = 'ui/CHANGED_ROUTE';
export const uiChangeAttributeAction = createAction('ui/CHANGE_ATTR');

export const initialState = {
  giveawayActiveTab: 1,
  language: LOCALES[0],
  disableAnalytics: false,
  cookieNotice: true,
  country: null,
  countryGuessed: false,
  cacheInfo: true,
  sendingForm: false,
};

const uiReducer = handleActions(
  {
    ["@@redux-form/START_SUBMIT"]: (state, action) => ({ ...state, sendingForm: true }),
    ["@@redux-form/STOP_SUBMIT"]: (state, action) => ({ ...state, sendingForm: false }),
    [UI_CHANCE_ATTR]: (state, action) => ({ ...state, ...action.payload }),
    [combineActions(GET_USERS_SETTINGS_RESPONSE, PUT_USERS_SETTINGS_RESPONSE)]: (state, action) => ({ ...state, language: action.payload.preferences.ui_language || state.language || LOCALES[0] }),
  },
  initialState,
);

export default uiReducer;
