import React from 'react';
import { ContentBox } from 'common/ContentBox';
import styled from 'styled-components';
import CollapsingTable from 'common/CollapsingTable';
import Link from 'redux-first-router-link';
import { Translation } from 'react-i18next';
import { RAFFLE_STATE_FINISHED } from 'helpers/const';
import Status from '../columns/Status';
import { Title } from '../columns/Title';
import { Numeric } from '../columns/Numeric';
import Button from 'common/MaterialUI/Button';
import { Pagination } from '../../../common/Pagination';
import { media } from '../../../styles';
import ActionButtonWithToolTip from '../ActionButtonWithToolTip';
import EmptyBox from '../../../common/EmptyBox';
import { Empty } from './Empty';
import ContestTimer from '../../../common/ContestTimer';

const Layout = styled.div`
  table {
    ${media.desktop`
  
  th,
  td{
    width: 20%;
  }


  th:nth-last-child(2),
  td:nth-last-child(2){
    width: 7%;
  }

  
  
  th:first-child,
  td:first-child {
    width: 50%;
  }
  `};
  }
  width: 100%;

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonArea = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: space-between;

  ${media.tabletMax`
    flex-direction: column-reverse;
    justify-content: center;

    > * + * {
      margin-bottom: 20px;
    }
  `};

  a {
    display: flex;
    justify-content: center;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  > * + * {
    margin-left: 5px !important;
  }
`;

export class View extends React.PureComponent {
  state = {
    disableCurrent: false,
    disableFinished: false
  };

  componentDidMount() {
    const { load, limit } = this.props;
    load(1, limit);
  }

  handleLoad = page => {
    const { load, limit } = this.props;
    load(parseInt(page), limit);
  };

  render() {
    const { data, total_pages, current_page, loading, loaded, openShareModal } = this.props;
    const { disableCurrent, disableFinished } = this.state;
    const { handleLoad } = this;

    const columns = [
      'giveaway',
      'ga_title_status',
      'ga_title_competitors',
      'ga_title_entries',
      'actions',
    ];

    const filterCurrent = g =>
      disableCurrent ? g.timer.mode == RAFFLE_STATE_FINISHED : true;
    const filterFinished = g =>
      disableFinished ? g.timer.mode != RAFFLE_STATE_FINISHED : true;

    const filteredRaffles = data && data.filter(
      g => filterCurrent(g) && filterFinished(g),
    );

    return (
      <Translation>
        {t => (
          <ContentBox
            keepMaxHeight
            vcenter={loading}
            title={t('dashboard_my_launched')}
            {...{ loading }}
          >
            <Layout>
              {loaded && (!filteredRaffles || filteredRaffles.length == 0) ? (
                <Empty />
              ) : loading ? (
                <EmptyBox noBorder loading />
              ) : (
                    <CollapsingTable.Table {...{ columns }}>
                      {filteredRaffles && filteredRaffles.map(giveaway => (
                        <CollapsingTable.Row key={giveaway.EVENTID}>
                          <CollapsingTable.Cell>
                            <Title {...{ giveaway }} />
                          </CollapsingTable.Cell>

                          <CollapsingTable.Cell>
                            <Status {...{ giveaway }} />
                          </CollapsingTable.Cell>
                          <CollapsingTable.Cell>
                            <Numeric
                              {...{ giveaway }}
                              value={giveaway.participants}
                            />
                          </CollapsingTable.Cell>

                          <CollapsingTable.Cell>
                            <Numeric
                              {...{ giveaway }}
                              value={giveaway.total_entries}
                            />
                          </CollapsingTable.Cell>

                          <CollapsingTable.Cell>
                            <Actions>

                            <ActionButtonWithToolTip
                                icon="icon-Ico16-Link"
                                content={t("Dashboard/LaunchedGiveaways/View.buttons.share")}
                                onClick={openShareModal(giveaway.EVENTID)}
                              />
                              <ContestTimer contest={giveaway}>{(timer) => <ActionButtonWithToolTip
                                disabled={timer && timer.mode == RAFFLE_STATE_FINISHED}
                                icon="icon-Ico16-Edit"
                                content={t('action_edit_giveaway')}
                                to={`/giveaways/${giveaway.EVENTID}/edit/prizes`}
                              />}</ContestTimer>
                              
                              <ActionButtonWithToolTip
                                icon="icon-Ico16-ArrowRight-Medium"
                                content={t('action_open_detailpage')}
                                to={`/giveaways/${giveaway.EVENTID}`}
                              />
                            </Actions>
                          </CollapsingTable.Cell>
                        </CollapsingTable.Row>
                      ))}
                    </CollapsingTable.Table>
                  )}
              {loaded &&
                filteredRaffles &&
                filteredRaffles.length > 0 && (
                  <ButtonArea>
                    <Button component={Link} to={`/create/prizes`} primary
                    ><span className="icon-Ico16-Plus-Medium" /><span>{t('launch_giveaway')}</span></Button>
                    <Pagination
                      total={total_pages}
                      active={current_page}
                      onChange={handleLoad}
                    />
                  </ButtonArea>
                )}
            </Layout>
          </ContentBox>
        )}
      </Translation>
    );
  }
}

export default View;
