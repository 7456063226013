
export const WAYTYPEID_TWITCH_FOLLOW = 5;
//export const WAYTYPE_TWITCH_FOLLOW = {"WAYTYPEID":5,"PLATFORMID":1,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":1,"social_link":"https://www.twitch.tv/{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPE_TWITCH_FOLLOW_TITLE = "gheed:twitch:follow"
export const WAYTYPE_TWITCH_FOLLOW = {"WAYTYPEID":28,"PLATFORMID":7,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://www.twitch.tv/{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0, "title": WAYTYPE_TWITCH_FOLLOW_TITLE}
export const WAYTYPEID_TWITCH_SUBSCRIBE = 17;
export const WAYTYPE_TWITCH_SUBSCRIBE = {"WAYTYPEID":17,"PLATFORMID":1,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":0,"warn_gambling":1,"connect_required":1,"social_link":"https://www.twitch.tv/{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_TWITTER_FOLLOW = 18;
/*export const WAYTYPE_TWITTER_FOLLOW = {"WAYTYPEID":18,"PLATFORMID":4,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":1,"social_link":"https://twitter.com/{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};*/
export const WAYTYPE_TWITTER_FOLLOW_TITLE = "gheed:twitter:follow"
export const WAYTYPE_TWITTER_FOLLOW = {"WAYTYPEID":28,"PLATFORMID":7,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://twitter.com/{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0, "title": WAYTYPE_TWITTER_FOLLOW_TITLE};
export const WAYTYPEID_TWITTER_RETWEET = 19;
/*export const WAYTYPE_TWITTER_RETWEET = {"WAYTYPEID":19,"PLATFORMID":4,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":1,"social_link":"https://twitter.com/{{BENEFICIARY}}/status/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};*/
export const WAYTYPE_TWITTER_RETWEET_TITLE = "gheed:twitter:retweet"
export const WAYTYPE_TWITTER_RETWEET = {"WAYTYPEID":28,"PLATFORMID":7,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://twitter.com/{{beneficiary_display_name}}/status/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0, "title": WAYTYPE_TWITTER_RETWEET_TITLE};
//export const WAYTYPEID_TWITTER_TWEET = 20; // OBSOLETE!!!!!
/*export const WAYTYPE_TWITTER_TWEET = {"WAYTYPEID":20,"PLATFORMID":4,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":1,"social_link":"https://twitter.com/{{BENEFICIARY}}/status/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};*/
//export const WAYTYPE_TWITTER_TWEET = {"WAYTYPEID":28,"PLATFORMID":7,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0, "title": WAYTYPE_TWITTER_TWEET_TITLE};
export const WAYTYPEID_TWITTER_HASHTAG = 21;
export const WAYTYPE_TWITTER_HASHTAG = {"WAYTYPEID":21,"PLATFORMID":4,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":1,"social_link":"https://twitter.com/{{BENEFICIARY}}/status/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_FACEBOOK_LIKE = 25;
export const WAYTYPE_FACEBOOK_LIKE = {"WAYTYPEID":25,"PLATFORMID":5,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://www.facebook.com/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_YOUTUBE_SUBSCRIBE = 26;
export const WAYTYPE_YOUTUBE_SUBSCRIBE = {"WAYTYPEID":26,"PLATFORMID":6,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://www.youtube.com/channel/{{BENEFICIARY}}?sub_confirmation=1","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_YOUTUBE_VIDEOWATCH = 27;
export const WAYTYPE_YOUTUBE_VIDEOWATCH = {"WAYTYPEID":27,"PLATFORMID":6,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://www.youtube.com/watch?v={{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_OTHER_VISIT = 28;
export const WAYTYPE_OTHER_VISIT = {"WAYTYPEID":28,"PLATFORMID":7,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_GHEED_COINS = 29;
export const WAYTYPE_GHEED_COINS = {"WAYTYPEID":29,"PLATFORMID":8,"is_active":1,"repetitions":0,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"StreamerCurrency","remote_validation":0};
export const WAYTYPEID_GHEED_BONUSCOINS = 30;
export const WAYTYPE_GHEED_BONUSCOINS = {"WAYTYPEID":30,"PLATFORMID":8,"is_active":1,"repetitions":0,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_INSTAGRAM_FOLLOW = 33;
export const WAYTYPE_INSTAGRAM_FOLLOW = {"WAYTYPEID":33,"PLATFORMID":11,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://www.instagram.com/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_OTHER_NEWSLETTER_VISIT = 34;
export const WAYTYPE_OTHER_NEWSLETTER_VISIT = {"WAYTYPEID":34,"PLATFORMID":7,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_GHEED_ENTER_ANOTHER_GIVEAWAY = 35;
export const WAYTYPE_GHEED_ENTER_ANOTHER_GIVEAWAY = {"WAYTYPEID":35,"PLATFORMID":8,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":0,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_MIXER_FOLLOW = 36;
export const WAYTYPE_MIXER_FOLLOW = {"WAYTYPEID":36,"PLATFORMID":12,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":1,"social_link":"https://mixer.com/{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_GHEED_COMPLETE_ALL_OTHER_ENTRYWAYS = 37;
export const WAYTYPE_GHEED_COMPLETE_ALL_OTHER_ENTRYWAYS = {"WAYTYPEID":37,"PLATFORMID":8,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":0,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":1,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_STEAM_JOIN = 38;
export const WAYTYPE_STEAM_JOIN = {"WAYTYPEID":38,"PLATFORMID":14,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://steamcommunity.com/groups/{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_SNAPCHAT_FOLLOW = 39;
export const WAYTYPE_SNAPCHAT_FOLLOW = {"WAYTYPEID":39,"PLATFORMID":15,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://snapchat.com/add/{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_OTHER_DOWNLOAD = 40;
export const WAYTYPE_OTHER_DOWNLOAD = {"WAYTYPEID":40,"PLATFORMID":7,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":1};
export const WAYTYPEID_GHEED_MULTIPLE_CHOICE = 41;
export const WAYTYPE_GHEED_MULTIPLE_CHOICE = {"WAYTYPEID":41,"PLATFORMID":8,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_GHEED_SECRET_CODE = 42;
export const WAYTYPE_GHEED_SECRET_CODE = {"WAYTYPEID":42,"PLATFORMID":8,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":1};
export const WAYTYPEID_GHEED_CHOOSE_AN_IMAGE = 43;
export const WAYTYPE_GHEED_CHOOSE_AN_IMAGE = {"WAYTYPEID":43,"PLATFORMID":8,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_GHEED_ANSWER_A_QUESTION = 44;
export const WAYTYPE_GHEED_ANSWER_A_QUESTION = {"WAYTYPEID":44,"PLATFORMID":8,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_DISCORD_JOIN = 45;
export const WAYTYPE_DISCORD_JOIN = {"WAYTYPEID":45,"PLATFORMID":13,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://discordapp.com/invite/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_MIXER_SUBSCRIBE = 46;
export const WAYTYPE_MIXER_SUBSCRIBE = {"WAYTYPEID":46,"PLATFORMID":12,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":0,"warn_gambling":1,"connect_required":1,"social_link":"https://mixer.com/{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_GHEED_SINGLE_CHOICE = 47;
export const WAYTYPE_GHEED_SINGLE_CHOICE = {"WAYTYPEID":47,"PLATFORMID":8,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_TWITTER_VIEW_TWEET = 48;
/*export const WAYTYPE_TWITTER_VIEW_TWEET = {"WAYTYPEID":48,"PLATFORMID":4,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://twitter.com/{{BENEFICIARY}}/status/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};*/
export const WAYTYPE_TWITTER_VIEW_TWEET_TITLE = "gheed:twitter:view_tweet"
export const WAYTYPE_TWITTER_VIEW_TWEET = {"WAYTYPEID":28,"PLATFORMID":7,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://twitter.com/{{beneficiary_display_name}}/status/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0, "title": WAYTYPE_TWITTER_VIEW_TWEET_TITLE};
export const WAYTYPEID_INSTAGRAM_VIEW_POST = 49;
export const WAYTYPE_INSTAGRAM_VIEW_POST = {"WAYTYPEID":49,"PLATFORMID":11,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://www.instagram.com/p/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_FACEBOOK_VIEW_POST = 50;
export const WAYTYPE_FACEBOOK_VIEW_POST = {"WAYTYPEID":50,"PLATFORMID":5,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://www.facebook.com/{{beneficiary_display_name}}/posts/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_OTHER_CUSTOM_LINK_LABEL = 51;
export const WAYTYPE_OTHER_CUSTOM_LINK_LABEL = {"WAYTYPEID":51,"PLATFORMID":7,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_FACEBOOK_VISIT = 52;
export const WAYTYPE_FACEBOOK_VISIT = {"WAYTYPEID":52,"PLATFORMID":5,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://www.facebook.com/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_INSTAGRAM_VISIT = 53;
export const WAYTYPE_INSTAGRAM_VISIT = {"WAYTYPEID":53,"PLATFORMID":11,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://www.instagram.com/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_GHEED_JOIN_GIVEAWAY = 54;
export const WAYTYPE_GHEED_JOIN_GIVEAWAY = {"WAYTYPEID":54,"PLATFORMID":8,"is_active":1,"repetitions":1,"has_cooldown":0,"has_mandatory":0,"warn_gambling":0,"connect_required":0,"social_link":"{{beneficiary_display_name}}","beneficiary_allowempty":1,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_TIKTOK_PROFILE_VISIT = 55;
export const WAYTYPE_TIKTOK_PROFILE_VISIT = {"WAYTYPEID":55,"PLATFORMID":16,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://www.tiktok.com/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};
export const WAYTYPEID_TIKTOK_POST_VISIT = 56;
export const WAYTYPE_TIKTOK_POST_VISIT = {"WAYTYPEID":56,"PLATFORMID":16,"is_active":1,"repetitions":1,"has_cooldown":1,"has_mandatory":1,"warn_gambling":0,"connect_required":0,"social_link":"https://www.tiktok.com/{{BENEFICIARY}}","beneficiary_allowempty":0,"beneficiary_default":"","remote_validation":0};


export const WAYTYPES = {
[WAYTYPEID_TWITCH_FOLLOW]: WAYTYPE_TWITCH_FOLLOW,
[WAYTYPEID_TWITCH_SUBSCRIBE]: WAYTYPE_TWITCH_SUBSCRIBE,
[WAYTYPEID_TWITTER_FOLLOW]: WAYTYPE_TWITTER_FOLLOW,
[WAYTYPEID_TWITTER_RETWEET]: WAYTYPE_TWITTER_RETWEET,
// [WAYTYPEID_TWITTER_TWEET]: WAYTYPE_TWITTER_TWEET,
[WAYTYPEID_TWITTER_HASHTAG]: WAYTYPE_TWITTER_HASHTAG,
[WAYTYPEID_FACEBOOK_LIKE]: WAYTYPE_FACEBOOK_LIKE,
[WAYTYPEID_YOUTUBE_SUBSCRIBE]: WAYTYPE_YOUTUBE_SUBSCRIBE,
[WAYTYPEID_YOUTUBE_VIDEOWATCH]: WAYTYPE_YOUTUBE_VIDEOWATCH,
[WAYTYPEID_OTHER_VISIT]: WAYTYPE_OTHER_VISIT,
[WAYTYPEID_GHEED_COINS]: WAYTYPE_GHEED_COINS,
[WAYTYPEID_GHEED_BONUSCOINS]: WAYTYPE_GHEED_BONUSCOINS,
[WAYTYPEID_INSTAGRAM_FOLLOW]: WAYTYPE_INSTAGRAM_FOLLOW,
[WAYTYPEID_OTHER_NEWSLETTER_VISIT]: WAYTYPE_OTHER_NEWSLETTER_VISIT,
[WAYTYPEID_GHEED_ENTER_ANOTHER_GIVEAWAY]: WAYTYPE_GHEED_ENTER_ANOTHER_GIVEAWAY,
[WAYTYPEID_MIXER_FOLLOW]: WAYTYPE_MIXER_FOLLOW,
[WAYTYPEID_GHEED_COMPLETE_ALL_OTHER_ENTRYWAYS]: WAYTYPE_GHEED_COMPLETE_ALL_OTHER_ENTRYWAYS,
[WAYTYPEID_STEAM_JOIN]: WAYTYPE_STEAM_JOIN,
[WAYTYPEID_SNAPCHAT_FOLLOW]: WAYTYPE_SNAPCHAT_FOLLOW,
[WAYTYPEID_OTHER_DOWNLOAD]: WAYTYPE_OTHER_DOWNLOAD,
[WAYTYPEID_GHEED_MULTIPLE_CHOICE]: WAYTYPE_GHEED_MULTIPLE_CHOICE,
[WAYTYPEID_GHEED_SECRET_CODE]: WAYTYPE_GHEED_SECRET_CODE,
[WAYTYPEID_GHEED_CHOOSE_AN_IMAGE]: WAYTYPE_GHEED_CHOOSE_AN_IMAGE,
[WAYTYPEID_GHEED_ANSWER_A_QUESTION]: WAYTYPE_GHEED_ANSWER_A_QUESTION,
[WAYTYPEID_DISCORD_JOIN]: WAYTYPE_DISCORD_JOIN,
[WAYTYPEID_MIXER_SUBSCRIBE]: WAYTYPE_MIXER_SUBSCRIBE,
[WAYTYPEID_GHEED_SINGLE_CHOICE]: WAYTYPE_GHEED_SINGLE_CHOICE,
[WAYTYPEID_TWITTER_VIEW_TWEET]: WAYTYPE_TWITTER_VIEW_TWEET,
[WAYTYPEID_INSTAGRAM_VIEW_POST]: WAYTYPE_INSTAGRAM_VIEW_POST,
[WAYTYPEID_FACEBOOK_VIEW_POST]: WAYTYPE_FACEBOOK_VIEW_POST,
[WAYTYPEID_OTHER_CUSTOM_LINK_LABEL]: WAYTYPE_OTHER_CUSTOM_LINK_LABEL,
[WAYTYPEID_FACEBOOK_VISIT]: WAYTYPE_FACEBOOK_VISIT,
[WAYTYPEID_INSTAGRAM_VISIT]: WAYTYPE_INSTAGRAM_VISIT,
[WAYTYPEID_GHEED_JOIN_GIVEAWAY]: WAYTYPE_GHEED_JOIN_GIVEAWAY,
[WAYTYPEID_TIKTOK_PROFILE_VISIT]: WAYTYPE_TIKTOK_PROFILE_VISIT,
[WAYTYPEID_TIKTOK_POST_VISIT]: WAYTYPE_TIKTOK_POST_VISIT,
}