import { createLogic } from 'redux-logic';
import {
  getCategoriesRequestAction,
  getCartRequestAction,
  getRaffleByIdRequestAction,
  getPrizesByRaffleIdRequestAction,
  getWaysByRaffleIdRequestAction,
  getClaimPrizeRequestAction,
  getPublicUserIdRequestAction,

  getRaffleByIdEditRequestAction, getProductsByIdRequestAction, getWaysByRaffleIdStatusRequestAction, getRaffleByIdCouponsRequestAction, getRaffleByIdCouponsStatusRequestAction, getBlogpostBySlugRequestAction, getCssRequestAction,
} from '../modules/api';
import { ROUTE_TYPE_DASHBOARD, ROUTE_TYPE_GIVEAWAYS, ROUTE_TYPE_GIVEAWAY, ROUTE_TYPE_GIVEAWAY_EMBED, ROUTE_TYPE_CLAIM, ROUTE_TYPE_STREAMER, ROUTE_TYPE_GIVEAWAY_EDIT, ROUTE_TYPE_CREATE, ROUTE_TYPE_GIVEAWAY_STATS, ROUTE_PREFIX, ROUTE_TYPE_SETTINGS_SETUP, ROUTE_TYPE_DEV_BLOGPOST } from '../../helpers/const';
import { raffleCategoryListRequestAction, raffleDynamicListRequestAction } from '../modules/giveawayLists';
import _getCreatorImages from './_getCreatorImages';
import _redirectFromStatsPage from './_redirectFromStatsPage';
import { productCategoryListRequestAction } from '../modules/productLists';
import { RESTAPI } from 'rdx/modules/api/rest';
import { AUTH_LOGOUT_REQUEST, logoutRequestAction } from 'rdx/modules/auth';

import isStreamer from '../selectors/isStreamer';
import storeSetupRequired from '../selectors/storeSetupRequired';

import { push, replace, redirect } from 'redux-first-router';
import { modalAction, MODAL_CONNECT, showNotificationModal } from 'rdx/modules/modals';
import addRegionInfoToUser from './_addRegionInfoToUser';
import { runtimeConfig } from '../../runtimeConfig';

const routeChange = createLogic({
  type: /@@route\/.+/,
  cancelType: [AUTH_LOGOUT_REQUEST],
  /* validate({ getState, action }, allow, reject) {
    action.meta.location.current.pathname != action.meta.location.prev.pathname
      ? allow(action)
      : reject(action);
  }, */
  async process({ getState, cancelled$, action }, dispatch, done) {
    try {
      let state = getState();

      const source = RESTAPI.getSource();
      cancelled$.subscribe(() => {
        source.cancel('Request cancelled')
      });

      if (!state.products.categories || state.products.categories.length == 0) {
        dispatch(getCategoriesRequestAction());
      }



      // add region data to user if necessary 
      if (state.auth.user) {
        addRegionInfoToUser(dispatch, getState)
      }

      // Route-based extra data
      const routeType = state.location.type;

      if (ROUTE_TYPE_GIVEAWAYS == routeType) {
        dispatch(
          raffleCategoryListRequestAction([
            null,
            {},
            true
          ]),
        );
      }

      // Campaign Page BEG
      const campaign = runtimeConfig.CAMPAIGNS && runtimeConfig.CAMPAIGNS.campaigns && Object.values(runtimeConfig.CAMPAIGNS.campaigns).find(c => `${ROUTE_PREFIX}${c.path}` == routeType)

      if (campaign) {
        const { hostId, giveawayIds, path } = campaign;

        if (giveawayIds && giveawayIds.length) {
          dispatch(raffleDynamicListRequestAction([{
            id: giveawayIds,
          }, path]))
        } else if (hostId) {
          dispatch(raffleDynamicListRequestAction([{
            filter: `OWNERID eq ${hostId}`,
            orderby: 'active_to desc',
            limit: 999,
          }, path]))
        }
      }
      // Campaign Page END

      // load raffle details
      if (ROUTE_TYPE_GIVEAWAY == routeType && state.location.payload.EVENTID || ROUTE_TYPE_GIVEAWAY_EMBED == routeType && state.location.payload.EVENTID) {
        dispatch(
          getRaffleByIdRequestAction({ id: state.location.payload.EVENTID }),
        );
        dispatch(
          getPrizesByRaffleIdRequestAction({
            id: state.location.payload.EVENTID,
          }),
        );
        dispatch(
          state.auth.user ? getWaysByRaffleIdStatusRequestAction({
            id: state.location.payload.EVENTID,
          }) : getWaysByRaffleIdRequestAction({
            id: state.location.payload.EVENTID,
          }),
        );
        dispatch(getRaffleByIdCouponsRequestAction({
          id: state.location.payload.EVENTID,
          language: state.ui.language
        }))

        if (state.auth.user) {
          dispatch(getRaffleByIdCouponsStatusRequestAction({
            id: state.location.payload.EVENTID,
          }))
        }
      }

      // load claim stuff
      if (ROUTE_TYPE_CLAIM == routeType && state.location.payload.EVENTID) {
        dispatch(
          getClaimPrizeRequestAction({ id: state.location.payload.EVENTID }),
        );
      }

      // load streamer data
      if (ROUTE_TYPE_STREAMER == routeType && state.location.payload.USERID) {
        dispatch(
          getPublicUserIdRequestAction({ id: state.location.payload.USERID }),
        );
      }

      // -------------- Creator / Editing -------------- //


      // redirect if store page and necessary info is missing
      if ([ROUTE_TYPE_CREATE, ROUTE_TYPE_GIVEAWAY_EDIT].indexOf(routeType) != -1 && storeSetupRequired(state)) {
        dispatch(redirect({ type: ROUTE_TYPE_SETTINGS_SETUP }))
      }

      if (ROUTE_TYPE_CREATE == routeType && state.location.payload.mode == 'shop' && !state.location.payload.PRODUCTID) {
        dispatch(productCategoryListRequestAction(["filtered", {}, true]));
      }

      // load product details
      if (ROUTE_TYPE_CREATE == routeType && state.location.payload.PRODUCTID) {
        dispatch(getProductsByIdRequestAction({ id: state.location.payload.PRODUCTID }))
      }

      // Refresh Cart once or in Creator
      if (state.auth.user && isStreamer(state) && state.auth.user.email_verified) {
        if (ROUTE_TYPE_CREATE == routeType) {
          dispatch(getCartRequestAction());
        }
      }


      // load editing raffle in creator routes
      if (ROUTE_TYPE_GIVEAWAY_EDIT == routeType && state.location.payload.EVENTID) {
        dispatch(getRaffleByIdEditRequestAction({ id: state.location.payload.EVENTID }));
      }

      // load creator images in creator page 1 or editing
      if ([ROUTE_TYPE_CREATE, ROUTE_TYPE_GIVEAWAY_EDIT].indexOf(routeType) != -1 && state.location.payload.mode == 'details') {
        dispatch(_getCreatorImages())
      }

      if (ROUTE_TYPE_GIVEAWAY_STATS == routeType) {
        dispatch(_redirectFromStatsPage);
      }

      //redirect to correct dashboard view
      if (ROUTE_TYPE_DASHBOARD == routeType) {
        push(state.auth.user && isStreamer(state) ? '/dashboard/streamer' : '/dashboard/viewer');
      }


      // show modals if necessary

      if (state.location.query && state.location.query.message) {
        dispatch(showNotificationModal({
          title: 'modals.notifications.notice',
          translate: false,
          htmlContent: state.location.query.message
        }))
        dispatch(replace(state.location.pathname))
      }


      if (state.location.query && state.location.query.error) {
        dispatch(showNotificationModal({
          title: 'modals.notifications.oh_snap',
          translate: false,
          htmlContent: state.location.query.error
        }))
        dispatch(replace(state.location.pathname))
      }

      // handle email flow related modals

      if (state.location.query && state.location.query.modal) {

        if (["verification", "reset"].indexOf(state.location.query.modal) != -1) {
          dispatch(modalAction({ modal: MODAL_CONNECT, data: { view: 'email', emailView: state.location.query.modal } }))
        }

      }

      // -------------- BLog Post Preview -------------- //

      if (ROUTE_TYPE_DEV_BLOGPOST == routeType && state.location.payload.slug) {
        dispatch(getBlogpostBySlugRequestAction({ slug: state.location.payload.slug }));
        dispatch(getCssRequestAction());
      }

    } catch (error) {
      console.log(error)
      dispatch(logoutRequestAction())
    }

    done();
  },
});

export default routeChange; 